import { FormikErrors } from "formik";
import React from "react";
import { Template } from "../../../models";
import SwitchContainer from "./common/SwitchContainer";

type EmbedMediaProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const EmbedMedia = ({ values, setFieldValue }: EmbedMediaProps) => {
  const includeEmbed = !!values?.include_web_embed;
  return (
    <SwitchContainer
      title="Embed the video into the Article"
      subTitle=""
      tooltip="This will embed the video right into the article."
      enabled={includeEmbed}
      setEnabled={() =>
        setFieldValue("include_web_embed", !values?.include_web_embed)
      }
    />
  );
};

export default EmbedMedia;
