import { FormikErrors } from "formik";
import React from "react";
import SelectV2, { OptionType } from "../../../components/CustomSelect";
import InfoTooltip from "../../../components/InfoTooltip";
import RadioGroup, { Option } from "../../../components/RadioGroup";
import {
  ImageSizeEnum,
  ImageStyleEnum,
  ImageToneEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import TemplateContainer from "./common/TemplateContainer";

// export const enum ImageSizeEnum {
//   IMG_1024x1024 = 1,
//   IMG_1024x1792 = 2,
//   IMG_1792x1024 = 3,
// }

export const imageSizeOptions = [
  { id: ImageSizeEnum.LANDSCAPE, name: "LANDSCAPE", description: "1792x1024" },
  { id: ImageSizeEnum.PORTRAIT, name: "PORTRAIT", description: "1024x1792" },
  { id: ImageSizeEnum.SQUARE, name: "SQUARE", description: "1024x1024" },
];

export const imageStyleOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: ImageStyleEnum.MINIMALISTIC,
    label: "Minimalistic",
    description:
      "Simplified portrayal focusing on essential elements with clean lines",
  },
  {
    value: ImageStyleEnum.GRADIENT,
    label: "Gradient",
    description:
      "Smooth transitions between colors for a dynamic and engaging visual",
  },
  {
    value: ImageStyleEnum.REALISTIC,
    label: "Realistic",
    description:
      "Detailed depiction mimicking real-world objects and scenes with precision",
  },
  {
    value: ImageStyleEnum.ABSTRACT,
    label: "Abstract",
    description:
      "Non-representational interpretation featuring unconventional shapes and colors",
  },
  {
    value: ImageStyleEnum.SURREAL,
    label: "Surreal",
    description:
      "Dreamlike depiction defying conventional reality with unexpected elements",
  },
  {
    value: ImageStyleEnum.VINTAGE,
    label: "Vintage",
    description:
      "Nostalgic style evoking aesthetics from the past with faded colors",
  },
  {
    value: ImageStyleEnum.FUTURISTIC,
    label: "Futuristic",
    description:
      "Forward-looking portrayal featuring sleek and modern elements",
  },
  {
    value: ImageStyleEnum.IMPRESSIONISTIC,
    label: "Impressionistic",
    description: "Blurry and dappled brushstrokes",
  },
  {
    value: ImageStyleEnum.OIL_PAINTING,
    label: "Oil Painting",
    description: "Oil paint on canvas with bold and dramatic brush strokes",
  },
  {
    value: ImageStyleEnum.PIXEL_ART,
    label: "Pixel Art",
    description: "Blocky, pixelated graphics reminiscent of early video games",
  },
  {
    value: ImageStyleEnum.WATERCOLOR,
    label: "Watercolor",
    description:
      "Soft and fluid depiction created with transparent watercolor paints",
  },
  {
    value: ImageStyleEnum.POP_ART,
    label: "Pop Art",
    description: "Bold and vibrant style inspired by popular culture icons",
  },
  {
    value: ImageStyleEnum.CARTOON,
    label: "Cartoon",
    description:
      "Playful representation resembling illustrations from comics or cartoons",
  },
];

export const imageToneOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: ImageToneEnum.VIBRANT,
    label: "Vibrant",
    description: "Bold and striking colors that catch attention",
  },
  {
    value: ImageToneEnum.GRADIENT,
    label: "Gradient",
    description:
      "Smooth transitions between colors for a dynamic and engaging visual",
  },
  {
    value: ImageToneEnum.LIGHT,
    label: "Light",
    description: "Bright and airy tones for a fresh and uplifting feel",
  },
  {
    value: ImageToneEnum.BRIGHT,
    label: "Bright",
    description: "High contrast and saturation for a lively and energetic mood",
  },
  {
    value: ImageToneEnum.SOFT,
    label: "Soft",
    description:
      "Gentle contrast and subdued colors for a tranquil and calming ambiance",
  },
  {
    value: ImageToneEnum.MOODY,
    label: "Moody",
    description:
      "Dark tones and deep shadows for a dramatic and mysterious atmosphere",
  },
  {
    value: ImageToneEnum.VINTAGE,
    label: "Vintage",
    description: "Sepia tones and faded colors for a nostalgic and retro look",
  },
  {
    value: ImageToneEnum.EARTHY,
    label: "Earthy",
    description:
      "Browns, tans, and greens inspired by nature for a grounded feel",
  },
  {
    value: ImageToneEnum.HIGH_KEY,
    label: "High Key",
    description: "Bright, even lighting for a clean and minimalist aesthetic",
  },
  {
    value: ImageToneEnum.LOW_KEY,
    label: "Low Key",
    description: "Dark, shadowy lighting for a mysterious and dramatic effect",
  },
  {
    value: ImageToneEnum.GRAYSCALE,
    label: "Grayscale",
    description: "Black and white tones for a classic and timeless appeal",
  },
];

type ImageSettingsProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

const ImageSettings = ({ values, setFieldValue }: ImageSettingsProps) => {
  const includeImage = !!values?.include_image;
  if (!includeImage) {
    return null;
  }
  return (
    <div className="animate-fadeIn">
      <TemplateContainer>
        {includeImage && (
          <div className="space-y-6">
            <div className="w-max">
              <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
                Image size
                <InfoTooltip
                  id="image_size"
                  tooltip="This is your preferred image size. Landscape is wide, portrait is tall, and then there is square."
                />
              </h3>
              <RadioGroup
                options={imageSizeOptions}
                name="image_size_id"
                selected={imageSizeOptions.find(
                  ({ id }) => id === values?.image_size_id
                )}
                onClick={({ id }: Option) => setFieldValue("image_size_id", id)}
              />
            </div>
            <div>
              <SelectV2
                label="Image style"
                placeholder="-"
                tooltip="The overall aesthetic or visual appearance of the image."
                options={imageStyleOptions}
                selected={imageStyleOptions.find(
                  (option) => option.value === values?.image_style_id
                )}
                setSelected={(option) => {
                  setFieldValue("image_style_id", option?.value);
                }}
              />
            </div>
            <div>
              <SelectV2
                label="Image tone"
                placeholder="-"
                tooltip="Tone controls the mood and lightness or darkness of the image. Tone will established the mood and detail visibility via brightness, contrast, and shadow."
                options={imageToneOptions}
                selected={imageToneOptions.find(
                  (option) => option.value === values?.image_tone_id
                )}
                setSelected={(option) => {
                  setFieldValue("image_tone_id", option?.value);
                }}
              />
            </div>
          </div>
        )}
      </TemplateContainer>
    </div>
  );
};

export default ImageSettings;
