import { Moment } from "moment-timezone";
import AnthropicSVG from "./assets/svg/anthropic.svg";
import AppleSVG from "./assets/svg/apple.svg";
import ChatGPTSVG from "./assets/svg/chatgpt.svg";
import GeminiSVG from "./assets/svg/gemini.svg";
import InstagramSVG from "./assets/svg/instagram.svg";
import SpotifySVG from "./assets/svg/spotify.svg";
import UploadSVG from "./assets/svg/upload.svg";
import YouTubeSVG from "./assets/svg/youtube.svg";
import { EngineEnum } from "./enums/engine";
import { SourceEnum } from "./enums/template";
import { BASE_URL, GENERATE_PRESIGNED_URL } from "./redux/apiEndPoints";

export const generateRandomNumber = (noOfDigits = 4, alphaNumeric = false) => {
  const min = Math.pow(10, noOfDigits - 1);
  const max = Math.pow(10, noOfDigits) - 1;
  const randomNumber = Math.floor(min + Math.random() * (max - min + 1));
  if (alphaNumeric) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < noOfDigits; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  } else {
    return randomNumber.toString();
  }
};

export const formatTimeHHMM = (timeString: string) => {
  let formattedTimeString = timeString;
  if (timeString?.split(":")?.length > 2) {
    formattedTimeString = timeString.substring(0, 5);
  }

  // Parse the hour component from the time string
  const [hourStr, minuteStr] = formattedTimeString.split(":");
  const hour = parseInt(hourStr);

  // Convert the hour component to 12-hour format with AM/PM indication
  const convertedHour = hour % 12 === 0 ? 12 : hour % 12;
  const ampm = hour < 12 ? "AM" : "PM";

  // Construct the formatted time string
  const convertedTime = `${convertedHour}:${minuteStr} ${ampm}`;

  return convertedTime;
};

export const formatDateUS = (
  dateString: string,
  year: boolean = true,
  monthLength: "long" | "short" = "short"
) => {
  return new Date(dateString).toLocaleDateString("en-US", {
    year: year ? "numeric" : undefined,
    month: monthLength,
    day: "numeric",
  });
};

export const formatNumberWithCommas = (number: number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const ensureTrailingSlash = (url: string): string => {
  // Check if the url ends with a '/' and if not, append it
  return url.endsWith("/") ? url : url + "/";
};

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezoneAdjustDate = (date: string, timezone: string) => {
  return new Date(date).toLocaleDateString("en-US", {
    timeZone: timezone,
  });
};

export const getCurrentTime = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours().toString().padStart(2, "0"); // Get hours and pad with leading zero if necessary
  const minutes = currentTime.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero if necessary
  return `${hours}:${minutes}`;
};

export const getSubscriptionDaysRemaining = (
  endDateStr: string,
  timezone: string
) => {
  // Parse the end date string into a Date object
  const endDate = new Date(
    new Date(endDateStr).toLocaleDateString("en-US", {
      timeZone: timezone,
    })
  );

  // Get the current date
  const currentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: timezone })
  );

  // Calculate the difference in time (in milliseconds)
  const differenceInTime = endDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const getDaysLeft = (
  endDateMoment: Moment,
  currentDateMoment: Moment
) => {
  return endDateMoment.diff(currentDateMoment, "days");
};

export const getPresignedURL = async (
  file: {
    name: string;
    type: string;
  },
  uploadType: "avatars" | "media",
  token: string
) => {
  try {
    const url = `${BASE_URL}${GENERATE_PRESIGNED_URL}?upload_type=${uploadType}&file_name=${encodeURIComponent(file.name)}&content_type=${encodeURIComponent(file.type)}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to get presigned URL: ${response.statusText}`);
    }

    const data = await response.json();
    return data.presigned_url;
  } catch (error) {
    console.error("Error getting presigned URL:", error);
    throw error;
  }
};

export const uploadAvatar = async (file: any, token: string) => {
  let uploadedFileURL = "";
  const presignedURL = await getPresignedURL(file, "avatars", token);
  await fetch(presignedURL, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  }).then(async (res) => {
    uploadedFileURL = res.url.split("?")?.[0];
  });
  return uploadedFileURL;
};

export const uploadMedia = async (
  file: File,
  token: string,
  options: {
    onProgress?: (progress: number, loaded: number, total: number) => void;
    fileName?: string;
    signal?: AbortSignal;
  }
): Promise<string> => {
  try {
    const presignedURL = await getPresignedURL(
      {
        name: options.fileName || file.name,
        type: file.type,
      },
      "media",
      token
    );

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      if (options.signal) {
        options.signal.addEventListener("abort", () => {
          xhr.abort();
          reject(new Error("AbortError"));
        });
      }

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && options.onProgress) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          options.onProgress(percentComplete, event.loaded, event.total);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const uploadedFileURL = presignedURL.split("?")[0];
          resolve(uploadedFileURL);
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => reject(new Error("Upload failed"));
      xhr.onabort = () => reject(new Error("AbortError"));

      xhr.open("PUT", presignedURL);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    });
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};

export function getRandomCharacterQuote(): string {
  const quotes = [
    'Dr. Henry "Indiana" Jones - Archaeologist, Professor, “It belongs in a Museum!"',
    'Kevin the Minion - indefatigable leader, "Tulaliloo ti amo"',
    "Lieutenant Pete Mitchell, call sign Maverick - US Navy Fighter Pilot",
  ];

  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
}

export function getCurrentDateTimeFormatted() {
  let current_datetime = new Date();

  // Get date components
  let year = current_datetime.getFullYear();
  let month = (current_datetime.getMonth() + 1).toString().padStart(2, "0");
  let day = current_datetime.getDate().toString().padStart(2, "0");

  // Get time components
  let hours = current_datetime.getHours().toString().padStart(2, "0");
  let minutes = current_datetime.getMinutes().toString().padStart(2, "0");
  let seconds = current_datetime.getSeconds().toString().padStart(2, "0");
  let milliseconds = current_datetime
    .getMilliseconds()
    .toString()
    .padStart(3, "0");

  // Get timezone offset in hours and minutes
  let tzoffset = current_datetime.getTimezoneOffset();
  let offset_hours = Math.abs(Math.floor(tzoffset / 60))
    .toString()
    .padStart(2, "0");
  let offset_minutes = Math.abs(tzoffset % 60)
    .toString()
    .padStart(2, "0");
  let timezone = `${tzoffset >= 0 ? "-" : "+"}${offset_hours}:${offset_minutes}`;

  // Combine date, time, milliseconds, and timezone
  let formatted_datetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;

  return formatted_datetime;
}

export function removeURLQueryParams(url: string) {
  return url.substring(0, url.indexOf("?"));
}

export function convertReelsToReelInURL(url: string) {
  return url.replace("/reels/", "/reel/");
}

export function removeURLQueryParamsExceptForV(url: string) {
  const queryStartIndex = url.indexOf("?");
  if (queryStartIndex === -1) {
    // No query params, return the original URL
    return url;
  }
  const baseUrl = url.substring(0, queryStartIndex);
  const queryParams = url.substring(queryStartIndex + 1).split("&");

  // Find the 'v' parameter
  const vParam: string | undefined = queryParams.find((param) =>
    param.toLowerCase().startsWith("v=")
  );
  return vParam ? `${baseUrl}?${vParam}` : baseUrl;
}

export function getSourceSVG(sourceId: SourceEnum) {
  const svgMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]: InstagramSVG,
    [SourceEnum.APPLE_PODCASTS]: AppleSVG,
    [SourceEnum.YOUTUBE_SHORTS]: YouTubeSVG,
    [SourceEnum.YOUTUBE_VIDEOS]: YouTubeSVG,
    [SourceEnum.SPOTIFY_PODCASTS]: SpotifySVG,
    [SourceEnum.UPLOAD_MEDIA]: UploadSVG,
  };
  return svgMap[sourceId];
}

export function isValidMediaLink(url: string, sourceId: SourceEnum) {
  const regexMap: { [key in SourceEnum]: RegExp } = {
    [SourceEnum.INSTAGRAM_REELS]:
      /^https:\/\/(www\.)?instagram\.com\/(reel|reels)\/[\w-]+\/?(\?.*)?$/,
    [SourceEnum.YOUTUBE_SHORTS]:
      /^https:\/\/(www\.)?youtube\.com\/shorts\/[\w-]+\/?(\?.*)?$/,
    [SourceEnum.APPLE_PODCASTS]:
      /^https:\/\/podcasts\.apple\.com\/[\w-]+\/podcast\/[\w-]+\/id\d+\?i=\d+$/, //eslint-disable-line
    [SourceEnum.YOUTUBE_VIDEOS]:
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/, //eslint-disable-line
    [SourceEnum.SPOTIFY_PODCASTS]:
      /^https:\/\/open\.spotify\.com\/episode\/[\w-]+\?si=[\w-]+$/,
    [SourceEnum.UPLOAD_MEDIA]: /^$/,
  };

  return regexMap[sourceId]?.test(url) ?? false;
}

export function getInvalidMediaLinkErrorMessage(sourceId: SourceEnum) {
  const errorMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]:
      "Invalid media link. Please enter a valid Instagram Reels URL, example: https://www.instagram.com/reel/CjBxMtVLpcd",
    [SourceEnum.YOUTUBE_SHORTS]:
      "Invalid media link. Please enter a valid YouTube Shorts URL, example: https://www.youtube.com/shorts/0LSDWhUnq0w",
    [SourceEnum.APPLE_PODCASTS]:
      "Invalid media link. Please enter a valid Apple Podcast URL, example: https://podcasts.apple.com/us/podcast/how-to-live-in-absolute-abundance-finding-the-gift/id1098413063?i=1000457381065",
    [SourceEnum.YOUTUBE_VIDEOS]:
      "Invalid media link. Please enter a valid YouTube Video URL, example: https://youtube.com/watch?v=aUQo9eWDZ7Y",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return errorMap[sourceId];
}

export function getMediaLinkPlaceholder(sourceId: SourceEnum) {
  const placeholderMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]: "https://www.instagram.com/reel/CjBxMtVLpcd",
    [SourceEnum.YOUTUBE_SHORTS]: "https://www.youtube.com/shorts/0LSDWhUnq0w",
    [SourceEnum.APPLE_PODCASTS]:
      "https://podcasts.apple.com/us/podcast/how-to-live-in-absolute-abundance-finding-the-gift/id1098413063?i=1000457381065",
    [SourceEnum.YOUTUBE_VIDEOS]: "https://youtube.com/watch?v=aUQo9eWDZ7Y",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return placeholderMap[sourceId];
}

export function getUsernamePlaceholder(sourceId: SourceEnum) {
  const placeholderMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]: "Example: johndoe",
    [SourceEnum.APPLE_PODCASTS]: "Example: 1532901566",
    [SourceEnum.YOUTUBE_SHORTS]: "Example: myhandle",
    [SourceEnum.YOUTUBE_VIDEOS]: "Example: myhandle",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return placeholderMap[sourceId];
}

export function getAutoSourceLabel(sourceId: SourceEnum) {
  const labelMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]: "Instagram Username",
    [SourceEnum.YOUTUBE_SHORTS]: "YouTube Handle",
    [SourceEnum.APPLE_PODCASTS]: "Apple Podcast Channel ID",
    [SourceEnum.YOUTUBE_VIDEOS]: "YouTube Handle",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return labelMap[sourceId];
}

export function getMediaLinkTooltip(sourceId: SourceEnum) {
  const tooltipMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]:
      "The direct link to a specific Instagram Reel. Note: To have enough content to generate an article we'll need at least 60 words from the video - which is approximately 20 seconds or more of regular speech.",
    [SourceEnum.YOUTUBE_SHORTS]:
      "The direct link to a specific YouTube Short. To have enough content to generate an article we'll need at least 60 words from the video - which is approximately 20 seconds or more of regular speech.",
    [SourceEnum.APPLE_PODCASTS]:
      "The direct link to a specific Apple Podcast. To have enough content to generate an article we'll need at least 60 words from the podcast - which is approximately 20 seconds or more of regular speech.",
    [SourceEnum.YOUTUBE_VIDEOS]:
      "The direct link to a specific YouTube Video. To have enough content to generate an article we'll need at least 60 words from the video - which is approximately 20 seconds or more of regular speech.",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return tooltipMap[sourceId];
}

export function getUsernameTooltip(sourceId: SourceEnum) {
  const tooltipMap: { [key in SourceEnum]: string } = {
    [SourceEnum.INSTAGRAM_REELS]: "The unique profile username.",
    [SourceEnum.YOUTUBE_SHORTS]: "The unique channel identifier.",
    [SourceEnum.APPLE_PODCASTS]: "The unique channel identifier.",
    [SourceEnum.YOUTUBE_VIDEOS]: "The unique channel identifier.",
    [SourceEnum.SPOTIFY_PODCASTS]: "",
    [SourceEnum.UPLOAD_MEDIA]: "",
  };
  return tooltipMap[sourceId];
}

export function identifyUrlSource(url: string) {
  if (url.includes("instagram.com")) {
    return SourceEnum.INSTAGRAM_REELS;
  } else if (url.includes("youtube.com/shorts")) {
    return SourceEnum.YOUTUBE_SHORTS;
  } else if (url.includes("youtube.com/watch")) {
    return SourceEnum.YOUTUBE_VIDEOS;
  } else if (url.includes("podcasts.apple.com")) {
    return SourceEnum.APPLE_PODCASTS;
  }
  return SourceEnum.INSTAGRAM_REELS;
}

export function sortNumbers(num: number[]) {
  return [...num].sort((a, b) => a - b);
}

export async function fetchHTMLFromS3(url: string) {
  const response = await fetch(url);
  return response;
}

export function downloadBlobAs(blob: Blob, filename: string) {
  const blobUrl = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(blobUrl);
}

export async function downloadAsHTML(url: string, filename = "download") {
  try {
    const response = await fetchHTMLFromS3(url);
    const blob = await response.blob();
    downloadBlobAs(blob, `${filename}.html`);
  } catch (error) {
    console.error("Download failed:", error);
  }
}

export async function downloadAsText(url: string, filename = "download") {
  try {
    const response = await fetchHTMLFromS3(url);
    const html = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const extractContent = (
      node: Node,
      listType: "ul" | "ol" | null = null,
      listLevel: number = 0
    ): string => {
      let content = "";
      let itemIndex = 1;

      for (const child of node.childNodes) {
        if (child.nodeType === Node.TEXT_NODE) {
          const text = child.textContent?.trimEnd() || "";
          if (text !== "View this post on Instagram") {
            content += text;
          }
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          const element = child as HTMLElement;
          switch (element.tagName) {
            case "A":
              const href = element.getAttribute("href") || "";
              const linkText = extractContent(child).trim();
              if (linkText && linkText === "Source Link") {
                content += `${linkText}: [${href}]\n`;
              }
              break;
            case "H1":
              content += `${extractContent(child)}\n`;
              break;
            case "H2":
            case "H3":
            case "H4":
            case "H5":
            case "H6":
              content += `\n${extractContent(child)}\n`;
              break;
            case "P":
              content += `\n${extractContent(child)}\n`;
              break;
            case "UL":
              content += `${extractContent(child, "ul", listLevel + 1)}\n`;
              break;
            case "OL":
              content += `${extractContent(child, "ol", listLevel + 1)}\n`;
              break;
            case "LI":
              const listItemContent = extractContent(child);
              if (listType === "ol") {
                content += `\n${itemIndex}. ${listItemContent}`;
                itemIndex++;
              } else {
                content += `\n- ${listItemContent}`;
              }
              break;
            default:
              content += extractContent(child);
          }
        }
      }
      return content;
    };

    const textContent = extractContent(doc.body);
    const blob = new Blob([textContent], { type: "text/plain" });
    downloadBlobAs(blob, `${filename}.txt`);
  } catch (error) {
    console.error("Download failed:", error);
  }
}

export async function downloadAsRichText(url: string, filename = "download") {
  try {
    const response = await fetchHTMLFromS3(url);
    const html = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const htmlToRtf = async (
      node: Node,
      isList = false,
      listType: "ul" | "ol" | null = null
    ): Promise<string> => {
      let rtfContent = "";
      let listCounter: number[] = [0]; // Start with zero to manage the first item in the list

      for (const child of Array.from(node.childNodes)) {
        if (child.nodeType === Node.TEXT_NODE) {
          if (
            child.textContent &&
            child.textContent.trim() !== "" &&
            child.textContent.trim() !== "View this post on Instagram"
          ) {
            rtfContent += child.textContent.replace(/\n/g, "\\par ");
          }
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          const element = child as HTMLElement;

          if (["IFRAME", "svg", "g", "path", "HR"].includes(element.tagName)) {
            // Ignore these elements
          } else if (element.tagName === "B" || element.tagName === "STRONG") {
            rtfContent += `\\b ${await htmlToRtf(child)}\\b0 `;
          } else if (element.tagName === "I" || element.tagName === "EM") {
            rtfContent += `\\i ${await htmlToRtf(child)}\\i0 `;
          } else if (element.tagName === "U") {
            rtfContent += `\\ul ${await htmlToRtf(child)}\\ulnone `;
          } else if (element.tagName === "P") {
            rtfContent += `\\par ${await htmlToRtf(child)}\\par `;
          } else if (element.tagName.startsWith("H")) {
            rtfContent += `\\par \\b\\fs24 ${await htmlToRtf(child)}\\b0\\fs20\\par `;
          } else if (element.tagName === "A") {
            const href = element.getAttribute("href");
            const linkText = await htmlToRtf(child);
            if (linkText) {
              rtfContent += href
                ? `{\\field{\\*\\fldinst{HYPERLINK "${href}"}}{\\fldrslt ${linkText}}}`
                : linkText;
            }
          } else if (element.tagName === "IMG") {
            const src = element.getAttribute("src");
            if (src) {
              const imageRtf = await convertImageToRtf(src);
              rtfContent += `\\par ${imageRtf} \\par`;
            }
          } else if (element.tagName === "UL") {
            rtfContent += `${await htmlToRtf(child, true, "ul")} \\par`;
          } else if (element.tagName === "OL") {
            rtfContent += `${await htmlToRtf(child, true, "ol")} \\par`;
          } else if (element.tagName === "LI") {
            if (isList && listType) {
              if (listType === "ol") {
                listCounter[listCounter.length - 1]++;
                rtfContent += `\\par ${listCounter[listCounter.length - 1]}. ${await htmlToRtf(child)}`;
              } else if (listType === "ul") {
                rtfContent += `\\par \\bullet  ${await htmlToRtf(child)}`;
              }
            } else {
              rtfContent += await htmlToRtf(child);
            }
          } else {
            rtfContent += await htmlToRtf(child);
          }
        }
      }

      return rtfContent;
    };

    const convertImageToRtf = async (src: string): Promise<string> => {
      try {
        const imageResponse = await fetch(src);
        const imageBlob = await imageResponse.blob();

        const canvas = document.createElement("canvas");
        const imageElement = new Image();
        imageElement.src = URL.createObjectURL(imageBlob);

        return await new Promise<string>((resolve, reject) => {
          imageElement.onload = () => {
            canvas.width = imageElement.width;
            canvas.height = imageElement.height;
            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(imageElement, 0, 0);
              canvas.toBlob((blob) => {
                if (blob) {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);

                  reader.onloadend = () => {
                    if (reader.result) {
                      const base64 = (reader.result as string).split(",")[1];
                      const rtfImage = `{\\pict\\pngblip\\picwgoal${imageElement.width * 5}\\pichgoal${imageElement.height * 5}\\bin ${convertBase64ToHex(base64)}}`;
                      resolve(rtfImage);
                    } else {
                      reject("Failed to convert image to Base64.");
                    }
                  };
                } else {
                  reject("Failed to convert canvas to blob.");
                }
              }, "image/png");
            } else {
              reject("Failed to get 2D context from canvas.");
            }
          };

          imageElement.onerror = () => reject("Failed to load image.");
        });
      } catch (error) {
        console.error("Failed to convert image:", error);
        return "";
      }
    };

    const convertBase64ToHex = (base64: string): string => {
      const binary = atob(base64);
      let hex = "";
      for (let i = 0; i < binary.length; i++) {
        const hexCode = binary.charCodeAt(i).toString(16);
        hex += (hexCode.length === 1 ? "0" : "") + hexCode;
      }
      return hex.toUpperCase();
    };

    const rtfHeader = "{\\rtf1\\ansi\\deff0 {\\fonttbl {\\f0 Arial;}}\\fs20 ";
    const rtfFooter = "}";
    const rtfContent =
      rtfHeader + (await htmlToRtf(doc.body)).trim() + rtfFooter;

    const blob = new Blob([rtfContent], { type: "application/rtf" });
    downloadBlobAs(blob, `${filename}.rtf`);
  } catch (error) {
    console.error("Download failed:", error);
  }
}

// Remove view as instagram link
export async function copyAsRichText(url: string) {
  try {
    // Fetch the HTML content from the S3 URL
    const response = await fetchHTMLFromS3(url);
    const html = await response.text();

    // If you want to include images, fetch and convert them here
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove the specific div containing the iframe
    const iframeDivs = doc.querySelectorAll("div > iframe");

    iframeDivs.forEach((iframe) => {
      const parentDiv = iframe.parentElement;
      if (parentDiv && parentDiv.parentElement) {
        parentDiv.parentElement.removeChild(parentDiv);
      }
    });

    // For each image in the document, fetch and convert the image
    const images = doc.querySelectorAll("img");
    for (const img of images) {
      const imageUrl = img.getAttribute("src");
      if (imageUrl) {
        const imageResponse = await fetch(imageUrl);
        const imageBlob = await imageResponse.blob();

        const canvas = document.createElement("canvas");
        const imageElement = new Image();
        imageElement.src = URL.createObjectURL(imageBlob);

        await new Promise<void>((resolve, reject) => {
          imageElement.onload = () => {
            canvas.width = imageElement.width;
            canvas.height = imageElement.height;
            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(imageElement, 0, 0);
              canvas.toBlob((blob) => {
                if (blob) {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob); // Convert the blob to a Base64 data URL

                  reader.onloadend = () => {
                    if (reader.result) {
                      img.setAttribute("src", reader.result as string);
                      resolve();
                    } else {
                      reject("Failed to convert image to Base64.");
                    }
                  };
                } else {
                  reject("Failed to convert canvas to blob.");
                }
              }, "image/png");
            } else {
              reject("Failed to get 2D context from canvas.");
            }
          };

          imageElement.onerror = () => reject("Failed to load image.");
        });
      }
    }

    const style = doc.createElement("style");
    style.textContent = `
      ul, ol {
        margin: 0;
        padding: 0;
      }
      ul li, ol li {
        margin-bottom: 0.5em; /* Adjust spacing as needed */
      }
      blockquote {
        border-left: 4px solid #ddd;
        padding-left: 16px;
        margin: 0;
        margin-bottom: 0.5em;
        font-style: italic;
      }
    `;
    doc.head.appendChild(style);

    // Convert the document back to HTML with the updated image sources
    const updatedHTML = new XMLSerializer().serializeToString(doc);

    // Copy as rich text
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([updatedHTML], { type: "text/html" }),
        "text/plain": new Blob([doc.body.textContent ?? ""], {
          type: "text/plain",
        }),
      }),
    ]);
  } catch (error) {
    console.error("Copy failed:", error);
  }
}

export const convertImage = async (webpUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(webpUrl)
      .then((response) => response.blob())
      .then((webpBlob) => {
        const img = new Image();
        img.src = URL.createObjectURL(webpBlob);

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");

          if (ctx) {
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              if (blob) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  if (reader.result) {
                    const base64 = reader.result as string;
                    resolve(base64);
                  }
                };
                reader.readAsDataURL(blob);
              } else {
                reject("Failed to convert image to blob.");
              }
            }, "image/png");
          } else {
            reject("Failed to get 2D context from canvas.");
          }
        };

        img.onerror = () => reject("Failed to load image.");
      })
      .catch((error) => reject(`Error converting image: ${error}`));
  });
};

export function getEngineSVG(engine: EngineEnum) {
  const svgMap: { [key in EngineEnum]: string } = {
    [EngineEnum.CLAUDE_3_5_SONNET_20240620]: AnthropicSVG,
    [EngineEnum.CLAUDE_3_5_SONNET_20241022]: AnthropicSVG,
    [EngineEnum.CLAUDE_3_OPUS_20240229]: AnthropicSVG,
    [EngineEnum.CLAUDE_3_5_HAIKU_20241022]: AnthropicSVG,
    [EngineEnum.GPT_4o_MINI_2024_07_18]: ChatGPTSVG,
    [EngineEnum.GPT_4_TURBO_2024_04_09]: ChatGPTSVG,
    [EngineEnum.GEMINI_1_5_PRO]: GeminiSVG,
  };
  return svgMap[engine];
}

export function getEngineName(engine: EngineEnum) {
  const svgMap: { [key in EngineEnum]: string } = {
    [EngineEnum.CLAUDE_3_5_SONNET_20240620]: "claude-3-5-sonnet-20240620",
    [EngineEnum.CLAUDE_3_5_SONNET_20241022]: "claude-3-5-sonnet-20241022",
    [EngineEnum.CLAUDE_3_OPUS_20240229]: "claude-3-opus-20240229",
    [EngineEnum.CLAUDE_3_5_HAIKU_20241022]: "claude-3-5-haiku-20241022",
    [EngineEnum.GPT_4o_MINI_2024_07_18]: "gpt-4o-mini-2024-07-18",
    [EngineEnum.GPT_4_TURBO_2024_04_09]: "gpt-4-turbo-2024-04-09",
    [EngineEnum.GEMINI_1_5_PRO]: "gemini-1-5-pro",
  };
  return svgMap[engine];
}

export const windowConfirm = (message: string) => {
  return window.confirm(message);
};
