import { FormikErrors } from "formik";
import React from "react";
import Input from "../../../components/Input";
import UploadMedia from "../../../components/UploadMedia";
import { SourceEnum } from "../../../enums/template";
import { Template } from "../../../models";
import {
  getCurrentTime,
  getMediaLinkPlaceholder,
  getMediaLinkTooltip,
  identifyUrlSource,
  windowConfirm,
} from "../../../utils";
import { AutomationSetup } from "./AutomationSetup";
import PlatformSelector from "./PlatformSelector";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type SourceNAutomationProps = {
  values: Partial<Template>;
  errors: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
  setErrors: any;
};

const SourceNAutomation = ({
  values,
  errors,
  setFieldValue,
  setErrors,
}: SourceNAutomationProps) => {
  const isEdit = Boolean(values?.template_id);
  const isAutoEnabled = !!values?.is_auto_enabled;
  const isUpload = values?.source_id === SourceEnum.UPLOAD_MEDIA;
  const resetAutoFields = () => {
    setFieldValue("is_auto_enabled", false);
    setFieldValue("username", "");
    setFieldValue("auto_days", []);
    setFieldValue("auto_time1", null);
    setFieldValue("auto_time2", null);
  };
  const resetMediaFields = () => {
    setFieldValue("media_url", "");
  };
  const resetEmbedField = () => {
    setFieldValue("include_web_embed", false);
  };
  const handlePlatformChange = (sourceId: SourceEnum) => {
    if (values?.template_id) return;

    if (
      (values.media_url || values.is_auto_enabled) &&
      windowConfirm(
        "Changing platform will reset your media link. Do you want to continue?"
      )
    ) {
      resetMediaFields();
      resetAutoFields();
      setFieldValue("source_id", sourceId);
    } else if (
      isAutoEnabled &&
      values.username &&
      windowConfirm(
        "Changing platform will reset your automation. Do you want to continue?"
      )
    ) {
      resetAutoFields();
      setFieldValue("source_id", sourceId);
    } else if (!values.media_url) {
      setFieldValue("source_id", sourceId);
    }

    if (sourceId === SourceEnum.UPLOAD_MEDIA && values.include_web_embed) {
      resetEmbedField();
    }
  };

  return (
    <div className="animate-fadeIn space-y-6">
      <TemplateContainer>
        <div className="space-y-6">
          <PlatformSelector
            isEdit={isEdit}
            source_id={values?.source_id!}
            handlePlatformChange={handlePlatformChange}
          />
          {!isUpload ? (
            <>
              <SwitchContainer
                title="Enable Automation"
                subTitle="Auto generate articles from your profile."
                enabled={isAutoEnabled}
                disabled={isEdit}
                setEnabled={() => {
                  setFieldValue("is_auto_enabled", !isAutoEnabled);
                  if (!isAutoEnabled) {
                    setFieldValue("auto_days", [1, 2, 3, 4, 5]);
                    setFieldValue("auto_time1", getCurrentTime());
                  } else {
                    setFieldValue("auto_days", []);
                    setFieldValue("auto_time1", null);
                    setFieldValue("auto_time2", null);
                  }
                }}
              />
              {/* Media Link or Profile Link */}
              {!isAutoEnabled && (
                <div className="relative">
                  <Input
                    label="Media link"
                    name="media_url"
                    value={values?.media_url || ""}
                    errorMessage={errors?.media_url as string}
                    errorPosition="bottom-left"
                    placeholder={getMediaLinkPlaceholder(values?.source_id!)}
                    tooltip={getMediaLinkTooltip(values?.source_id!)}
                    onChange={({ target: { value } }) => {
                      setFieldValue("media_url", value.trim());
                    }}
                    onBlur={({ target: { value } }) => {
                      const url = value.trim();
                      if (url) {
                        const source_id = identifyUrlSource(url);
                        setFieldValue("source_id", source_id);
                      }
                    }}
                  />
                </div>
              )}
              {isAutoEnabled && (
                <AutomationSetup
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              )}
            </>
          ) : (
            <UploadMedia
              mediaURL={values.media_url || ""}
              setMediaURL={(value) => setFieldValue("media_url", value)}
              mediaURLError={errors?.media_url as string}
              setErrors={setErrors}
            />
          )}
        </div>
      </TemplateContainer>
    </div>
  );
};

export default SourceNAutomation;
