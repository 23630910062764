import { PlusIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FormikErrors } from "formik";
import React, { useState } from "react";
import Button from "../../../components/Button";
import InfoTooltip from "../../../components/InfoTooltip";
import Input from "../../../components/Input";
import TimezoneSelector from "../../../components/TimezoneSelector";
import {
  AutomationDays,
  AutomationDaysMap,
  SourceEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import {
  getAutoSourceLabel,
  getCurrentTime,
  getUsernamePlaceholder,
  getUsernameTooltip,
  sortNumbers,
} from "../../../utils";

type Keys =
  | "timezone"
  | "is_auto_enabled"
  | "profile_url"
  | "media_url"
  | "destination_id"
  | "auto_days"
  | "auto_time1"
  | "auto_time2"
  | "post_url"
  | "post_media_url"
  | "username"
  | "app_password"
  | "post_status"
  | "post_author_id"
  | "post_category_id";

export const AutomationSetup = ({
  values,
  errors,
  setFieldValue,
}: {
  values: Partial<Template>;
  errors: FormikErrors<Template>;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const isEdit = Boolean(values?.template_id);
  return (
    <>
      <div className="relative">
        <Input
          label={getAutoSourceLabel(values.source_id as SourceEnum)}
          name="username1"
          value={values?.username || ""}
          disabled={isEdit}
          errorMessage={errors?.username as string}
          errorPosition="bottom-left"
          placeholder={getUsernamePlaceholder(values.source_id as SourceEnum)}
          tooltip={getUsernameTooltip(values.source_id as SourceEnum)}
          onChange={({ target: { value } }) => {
            if (isEdit) return;

            setFieldValue("username", value.trim());
          }}
        />
      </div>
      <div className="space-y-6">
        <SelectAutomationDaysTimes
          autoDays={sortNumbers(values?.auto_days! || [])}
          autoTime1={values?.auto_time1!}
          autoTime2={values?.auto_time2!}
          timezone={values.timezone as string}
          onChange={setFieldValue}
        />
      </div>
    </>
  );
};

const SelectAutomationDaysTimes = ({
  autoDays,
  autoTime1,
  autoTime2,
  timezone,
  onChange,
}: {
  autoDays: number[];
  autoTime1: string;
  autoTime2: string;
  timezone: string;
  onChange: (
    key: Keys,
    value: string | boolean | number | number[] | null
  ) => void;
}) => {
  const [showAutoTime2, setShowAutoTime2] = useState(autoTime2 || false);
  return (
    <div className="w-full space-x-3">
      <div className="grid grid-cols-1 gap-8">
        <div className="flex flex-col gap-3">
          <h3 className="block text-sm font-medium leading-6 text-gray-900">
            Select the days to run the automation
          </h3>
          <div className="flex gap-4">
            {AutomationDays.map((day) => (
              <div
                key={day}
                role="button"
                tabIndex={0}
                className={classNames(
                  "text-xs cursor-pointer rounded-full w-6 h-6 flex items-center justify-center border",
                  {
                    "border-primary bg-primary hover:bg-primaryHover text-white":
                      autoDays.includes(day),
                  },
                  {
                    "border-gray-200 hover:bg-primary/10 text-gray-500":
                      !autoDays.includes(day),
                  }
                )}
                onClick={() => {
                  if (autoDays.includes(day)) {
                    const updatedAutoDays = autoDays.filter((d) => d !== day);
                    onChange("auto_days", updatedAutoDays);
                  } else {
                    onChange("auto_days", [...autoDays, day]);
                  }
                }}
              >
                {AutomationDaysMap[day]}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="text-sm font-medium leading-6 text-gray-900 flex items-center gap-2">
            Select times
            <InfoTooltip
              id="select_times"
              tooltip="At the selected times, the profile will be checked for new content to generate articles."
            />
          </h3>
          <div className="grid gap-3 mt-2">
            <div className="max-w-[25rem]">
              <TimezoneSelector
                timezone={timezone}
                setTimezone={(timezone: string) =>
                  onChange("timezone", timezone)
                }
              />
            </div>
            <div className="flex gap-6 w-full items-end">
              <div className="w-48">
                <Input
                  label="Time 1"
                  type="time"
                  id="auto_time1"
                  className="leading-none text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full"
                  min="00:00"
                  max="24:00"
                  value={autoTime1}
                  onChange={(e) => {
                    onChange("auto_time1", e.target.value);
                  }}
                  name={"auto_time1"}
                />
              </div>
              {!showAutoTime2 && (
                <Button
                  variant="icon"
                  type="button"
                  onClick={() => {
                    setShowAutoTime2(true);
                    onChange("auto_time2", getCurrentTime());
                  }}
                >
                  <PlusIcon className="size-5 text-primary" />
                </Button>
              )}
            </div>
            {showAutoTime2 && (
              <div className="flex gap-6 w-full items-end">
                <div className="w-48">
                  <Input
                    label="Time 2"
                    type="time"
                    name="auto_time2"
                    id="auto_time2"
                    className="leading-none text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full"
                    min="00:00"
                    max="24:00"
                    value={autoTime2}
                    onChange={(e) => onChange("auto_time2", e.target.value)}
                  />
                </div>
                <Button
                  variant="icon"
                  type="button"
                  onClick={() => {
                    onChange("auto_time2", null);
                    setShowAutoTime2(false);
                  }}
                >
                  <XMarkIcon className="size-5 text-red-500" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
