import classNames from "classnames";
import React from "react";

export type Option = {
  id: any;
  name: any;
  description?: string;
};

type RadioGroupProps = {
  options: Option[];
  name: string;
  selected?: Option;
  disabled?: boolean;
  onClick: (option: Option) => void;
};

const RadioGroup = ({
  options,
  name,
  selected,
  onClick,
  disabled,
}: RadioGroupProps) => {
  return (
    <fieldset>
      <div className="flex flex-col md:flex-row gap-2 md:gap-5 md:items-center">
        {options.map((option: Option) => (
          <div
            key={option.id}
            className="relative flex items-start"
          >
            <div className="flex h-6 items-center">
              <input
                id={option.id}
                aria-describedby={`${option.id}-description`}
                name={name}
                type="radio"
                disabled={disabled}
                checked={selected?.id === option.id}
                onChange={() => onClick(option)}
                className={classNames(
                  "relative size-4 appearance-none rounded-full border border-gray-300 bg-white",
                  "before:absolute before:inset-1 before:rounded-full before:bg-white hover:bg-primary/10 hover:border-primary/10",
                  "checked:border-primary checked:bg-primary checked:hover:bg-primary checked:disabled:hover:bg-gray-100 checked:disabled:hover:border-gray-300",
                  "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary",
                  "disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400",
                  "forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                )}
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label
                htmlFor={option.id}
                className="text-gray-600"
              >
                {option.name}
              </label>
              {option.description && (
                <div
                  id={`${option.id}-description`}
                  className="text-gray-500 text-xs"
                >
                  {option.description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioGroup;
