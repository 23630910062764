import React from "react";
import InfoTooltip from "../../../components/InfoTooltip";
import RadioGroup, { Option } from "../../../components/RadioGroup";
import {
  FaqQuestionPrefixEnum,
  ImageSizeEnum,
  NoOfFaqEnum,
  PointOfViewEnum,
  SourceEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import EmbedMedia from "./EmbedMedia";
import SourceNAutomation from "./SourceNAutomation";
import TemplateName from "./TemplateName";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type SetupProps = {
  values: Template;
  errors: any;
  setFieldValue: any;
  setErrors: any;
};

export const pointOfViewOptions = [
  {
    id: PointOfViewEnum.THIRD_PERSON,
    name: "Third-person",
  },
  {
    id: PointOfViewEnum.FIRST_PERSON,
    name: "First-person",
  },
];

const Setup = ({ values, errors, setFieldValue, setErrors }: SetupProps) => {
  const includeImage = !!values?.include_image;
  const isUpload = values?.source_id === SourceEnum.UPLOAD_MEDIA;
  const includeFaq = Boolean(values?.include_faq);
  return (
    <div className="animate-fadeIn space-y-6">
      <TemplateName
        values={values}
        error={errors["name"] as string}
        setFieldValue={setFieldValue}
      />
      <SourceNAutomation
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
      />
      <TemplateContainer className="space-y-8">
        <SwitchContainer
          title="Include Image"
          subTitle="Add an image to the article."
          tooltip="A unique, non-stock image will be generated based on the content of the article."
          enabled={includeImage}
          setEnabled={() => {
            setFieldValue("include_image", !includeImage);
            if (!includeImage && !values.image_size_id) {
              setFieldValue("image_size_id", ImageSizeEnum.LANDSCAPE);
            }
          }}
        />
        {!isUpload && (
          <EmbedMedia
            values={values}
            setFieldValue={setFieldValue}
          />
        )}
        <div>
          <SwitchContainer
            title="Include FAQs"
            tooltip='Add a "Frequently Asked Questions" section to the bottom of the article.'
            enabled={includeFaq}
            setEnabled={() => {
              setFieldValue("include_faq", !values?.include_faq);
              if (!values?.include_faq && !values.faq_question_prefix_id) {
                setFieldValue(
                  "faq_question_prefix_id",
                  FaqQuestionPrefixEnum.Q_LETTER
                );
              }
              if (!values?.include_faq && !values.faq_count) {
                setFieldValue("faq_count", NoOfFaqEnum.THREE);
              }
            }}
          />
        </div>
        <div className="w-max">
          <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
            Point of view
            <InfoTooltip id="point_of_view">
              <div className="flex flex-col gap-4">
                <div>
                  First-person:
                  <div className="ml-6">
                    <div>
                      A more opinionated point of view — conveys the perspective
                      and thoughts of the speaker or writer.
                    </div>
                    <div>Pronouns: I, me, my, mine, we, our, us.</div>
                  </div>
                </div>
                <div>
                  Third-person:
                  <div className="ml-6">
                    <div>
                      A more professional point of view — conveys a neutral and
                      objective presentation of the source material.
                    </div>
                    <div>
                      Pronouns: he, she, it, him, her, his, hers, they, them,
                      their, theirs.
                    </div>
                  </div>
                </div>
              </div>
            </InfoTooltip>
          </h3>
          <RadioGroup
            options={pointOfViewOptions}
            name="point_of_view_id"
            selected={pointOfViewOptions.find(
              ({ id }) => id === values?.point_of_view_id
            )}
            onClick={({ id }: Option) => setFieldValue("point_of_view_id", id)}
          />
        </div>
      </TemplateContainer>
    </div>
  );
};

export default Setup;
