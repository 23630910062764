export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const APP_URL = process.env.REACT_APP_APP_URL ?? "";
export const STRIPE_PRICING_TABLE_ID =
  process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
export const STRIPE_RENEW_PRICING_TABLE_ID =
  process.env.REACT_APP_STRIPE_RENEW_PRICING_TABLE_ID;
export const STRIPE_PRICING_TABLE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PRICING_TABLE_PUBLISHABLE_KEY;
export const REWARDFUL_ID = process.env.REACT_APP_REWARDFUL_ID;
export const CF_TURNSTILE_SITE_KEY =
  process.env.REACT_APP_CF_TURNSTILE_SITE_KEY;
